const Menu = [
  {
    heading: "menu",
    route: "/super-admin",
    pages: [
      {
        sectionTitle: "Trainee",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Rollback Enrollment Status",
            route: "/super-admin/enrollment",
          },
          {
            heading: "Allow Multiple Enrollment",
            route: "/super-admin/multiple-enrollment",
          },
        ],
      },
      {
        sectionTitle: "Enrollment",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Unblock Batch Enrollment",
            route: "/super-admin/unblock-batch-enrollment",
          },
        ],
      },
      {
        sectionTitle: "Training Calendar",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Reset Training Calendar",
            route: "/super-admin/reset-training-calender",
          },
        ],
      },
      {
        sectionTitle: "Attendance",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Reset Attendance",
            route: "/super-admin/reset-attendance",
          },
          {
            heading: "Extend Attendance",
            route: "/super-admin/extend-attendance",
          },
          {
            heading: "Extend Attendance by Institute",
            route: "/super-admin/extend-bulk-attendance",
          },
        ],
      },
      {
        sectionTitle: "Assessment",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Reset Assessment",
            route: "/super-admin/reset-assessment",
          },
          {
            heading: "Reset Reassessment",
            route: "/super-admin/reset-reassessment",
          },
        ],
      },
      {
        sectionTitle: "Certification",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Reset Certification",
            route: "/super-admin/reset-certification",
          },
        ],
      },
      {
        sectionTitle: "Employment",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Reset Employment",
            route: "/super-admin/reset-employment",
          },
        ],
      },
      {
        sectionTitle: "Bill",
        route: "/super-admin",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-app-indicator",
        sub: [
          {
            heading: "Reset Bill",
            route: "/super-admin/reset-bill",
          },
        ],
      },
    ],
  },
];

export default Menu;
